import VueChartJs from "vue-chartjs";
import Vue from "vue";

const getChart = (type) => {
  switch (type) {
    case "LineChart":
      return VueChartJs.Line;
    case "BarChart":
      return VueChartJs.Bar;
    case "horizontalBar":
      return VueChartJs.HorizontalBar;
    case "PieChart":
      return VueChartJs.Pie;
    case "DoughnutChart":
    case "DonutChart":
      return VueChartJs.Doughnut;
    default:
      throw new Error("wrong chart type");
  }
};

const { reactiveProp } = VueChartJs.mixins

export default (type) => {
  return Vue.component(type, {
    extends: getChart(type),
    name: type,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        default: null,
      },
      options: {
        type: Object,
        default() {
          return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true,
              },
            },
            scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
          };
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.renderChart(this.chartData, this.options);
      })
    },
  });
};
