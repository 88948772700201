<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" md="6">
				<v-row>
					<v-col cols="12">
						<v-sheet>
							<v-select v-model="filtroResumen"
								:items="['HOY', 'AYER', 'ESTA SEMANA', 'ESTE MES', 'ESTE AÑO', 'TODO']" label="Resumen de"
								outlined hide-details></v-select>
						</v-sheet>
					</v-col>

					<v-col cols="12" sm="6" md="4" :key="i" v-for='attrs, i in [
						{ name: "Estudios realizados", value: resumen.estudios_realizados, icon: "mdi-reload" },
						{ name: "Estudios creados", value: resumen.estudios_creados, icon: "mdi-plus" },
						{ name: "Estudios pendientes", value: resumen.estudios_pendientes, icon: "mdi-eye" },
						{ name: "Estudios con incidencias", value: resumen.estudios_incidencia, icon: "mdi-alert-circle-outline" },
						{ name: "Instalaciones terminadas", value: resumen.instalaciones_terminadas, icon: "mdi-solar-power-variant" },
						{ name: "Instalaciones en curso", value: resumen.instalaciones_curso, icon: "mdi-hammer" },
					]'>
						<ValueCard v-bind="attrs" />
					</v-col>

					<v-col cols="12">
						<v-textarea hide-details v-model="resumen.texto_bienvenida" filled no-resize auto-grow
							:disabled="!$root.acceso('CONTROL_USUARIOS')" />
						<v-btn @click="guardarTexto" v-if="$root.acceso('CONTROL_USUARIOS')" color="primary" class="mt-2"
							small>Guardar</v-btn>
					</v-col>

				</v-row>

			</v-col>
			<v-col cols="12" md="6">
				<TarjetaDatos titulo="Histórico">
					<div class="pa-4">
						<component :is="BarChart" :chart-data="resumenHist" />
					</div>
				</TarjetaDatos>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<tarjeta-datos titulo="Calendario eventos">
					<CalendarioEstudio ref="calendario" :date="new Date()" />
				</tarjeta-datos>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import Chart from '@/components/charts/index.js';

export default {
	name: "Home",
	components: {
		ChartResumen: () => import('../charts/ChartResumen.vue'),
		TarjetaDatos: () => import('@/components/TarjetaDatos.vue'),
		ValueCard: () => import('@/components/ValueCard.vue'),
		AuxInput: () => import('@/components/AuxInput.vue'),
		CalendarioEstudio: () => import('@/modules/estudios/components/calendario/CalendarioEstudio.vue'),
	},
	data() {
		return {
			resumen: [],
			resumenHist: {},
			eventosHoy: [],
			filtroResumen: 'HOY',
		}
	},
	methods: {
		async getEventosHoy() {
			const { data } = await axios({
				method: "POST",
				url: `${process.env.VUE_APP_API_URL}/calendario_eventos`,
				data: {
					start: new Date().toISOString().substring(0, 10) + ' 00:00:00',
					end: new Date().toISOString().substring(0, 10) + ' 23:59:59',
				},
			});
			this.eventosHoy = data
		},
		async guardarTexto() {
			await axios({
				method: "POST",
				url: `${process.env.VUE_APP_API_URL}/resumen/texto_bienvenida`,
				data: {
					texto: this.resumen.texto_bienvenida
				},
			});
		},
		async getResumen() {
			const { data } = await axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/resumen`,
				params: {
					filtro: this.filtroResumen
				},
			});
			this.resumen = data
		},
		async getResumenHist() {
			const { data } = await axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/resumen/hist`,
			});

			let results = {
				labels: [],
				datasets: [
					{
						label: '',
						data: [],
						hoverBackgroundColor: [],
						backgroundColor: [],
					},
					{
						label: '',
						data: [],
						hoverBackgroundColor: [],
						backgroundColor: [],
					},
				],
			};

			let primary = this.$vuetify.theme.themes.light.primary
			let success = this.$vuetify.theme.themes.light.success

			data.forEach((bar, index) => {
				results.labels[index] = `${bar.year}-${bar.month}`;

				results.datasets[0].label = 'ESTUDIOS REALIZADOS';
				results.datasets[0].data[index] = bar.realizados;
				results.datasets[0].hoverBackgroundColor[index] = success;
				results.datasets[0].backgroundColor[index] = success + "a0";

				results.datasets[1].label = 'ESTUDIOS CREADOS';
				results.datasets[1].data[index] = bar.creados;
				results.datasets[1].hoverBackgroundColor[index] = primary;
				results.datasets[1].backgroundColor[index] = primary + "a0";
			});


			this.resumenHist = results
		}
	},
	async mounted() {
		this.getResumenHist();
	},
	computed: {
		BarChart() {
			return Chart('BarChart')
		},
	},
	watch: {
		filtroResumen: {
			handler() { this.getResumen() },
			immediate: true
		},
	}
};
</script>
