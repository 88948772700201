var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',[_c('v-select',{attrs:{"items":['HOY', 'AYER', 'ESTA SEMANA', 'ESTE MES', 'ESTE AÑO', 'TODO'],"label":"Resumen de","outlined":"","hide-details":""},model:{value:(_vm.filtroResumen),callback:function ($$v) {_vm.filtroResumen=$$v},expression:"filtroResumen"}})],1)],1),_vm._l(([
					{ name: "Estudios realizados", value: _vm.resumen.estudios_realizados, icon: "mdi-reload" },
					{ name: "Estudios creados", value: _vm.resumen.estudios_creados, icon: "mdi-plus" },
					{ name: "Estudios pendientes", value: _vm.resumen.estudios_pendientes, icon: "mdi-eye" },
					{ name: "Estudios con incidencias", value: _vm.resumen.estudios_incidencia, icon: "mdi-alert-circle-outline" },
					{ name: "Instalaciones terminadas", value: _vm.resumen.instalaciones_terminadas, icon: "mdi-solar-power-variant" },
					{ name: "Instalaciones en curso", value: _vm.resumen.instalaciones_curso, icon: "mdi-hammer" },
				]),function(attrs,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValueCard',_vm._b({},'ValueCard',attrs,false))],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"hide-details":"","filled":"","no-resize":"","auto-grow":"","disabled":!_vm.$root.acceso('CONTROL_USUARIOS')},model:{value:(_vm.resumen.texto_bienvenida),callback:function ($$v) {_vm.$set(_vm.resumen, "texto_bienvenida", $$v)},expression:"resumen.texto_bienvenida"}}),(_vm.$root.acceso('CONTROL_USUARIOS'))?_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","small":""},on:{"click":_vm.guardarTexto}},[_vm._v("Guardar")]):_vm._e()],1)],2)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('TarjetaDatos',{attrs:{"titulo":"Histórico"}},[_c('div',{staticClass:"pa-4"},[_c(_vm.BarChart,{tag:"component",attrs:{"chart-data":_vm.resumenHist}})],1)])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tarjeta-datos',{attrs:{"titulo":"Calendario eventos"}},[_c('CalendarioEstudio',{ref:"calendario",attrs:{"date":new Date()}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }